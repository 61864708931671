<template>
  <v-dialog :value="dialog" width="800" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>
          청구서 - 직접입금
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5" id="bankTransferBill">
        <h2>공급자</h2>
        <v-simple-table class="elevation-1" style="white-space:nowrap">
          <tbody>
            <tr>
              <td class="filled">사업자 등록번호</td>
              <td colspan="3">{{ businessInfo.businessNumber }}</td>
            </tr>
            <tr>
              <td class="filled">업체명</td>
              <td>{{ businessInfo.name }}</td>
              <td class="filled">대표자 명</td>
              <td>{{ businessInfo.presidentName }}</td>
            </tr>
            <tr>
              <td class="filled">주소</td>
              <td colspan="3">{{ businessInfo.address }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-divider class="my-5"></v-divider>
        <h2>직접입금 거래 내용</h2>
        <v-simple-table class="elevation-1" style="white-space:nowrap">
          <tbody>
            <tr>
              <td class="filled">사업자 등록번호</td>
              <td colspan="3">N/A</td>
            </tr>
            <tr>
              <td class="filled">업체명</td>
              <td>****</td>
              <td class="filled">대표자 명</td>
              <td>
                ***
              </td>
            </tr>
            <tr>
              <td class="filled">주소</td>
              <td colspan="3">N/A</td>
            </tr>

            <tr>
              <td class="filled">업태</td>
              <td>N/A</td>
              <td class="filled">종목</td>
              <td>
                N/A
              </td>
            </tr>
            <tr>
              <td class="filled">세금계산서 이메일</td>
              <td colspan="3">N/A</td>
            </tr>
            <tr>
              <td class="filled">신청일</td>
              <td>N/A</td>
              <td class="filled">세금계산서 발행일</td>
              <td>
                N/A
              </td>
            </tr>
            <tr>
              <td class="filled">충전 금액</td>
              <td>N/A</td>
              <td class="filled">부가세 금액</td>
              <td>
                N/A
              </td>
            </tr>
            <tr>
              <td class="filled">최종 입금액</td>
              <td colspan="3">N/A</td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="mt-3">
          <li>
            해당 청구서는 거래내역 확인을 위한 용도이며, 세금계산서는 별도로
            발행일에 발행됐습니다.
          </li>
          <li>
            청구서에 문제가 있을 경우, 노티아이 운영팀에 문의해주시기 바랍니다.
          </li>
        </ul>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn color="#ddd" @click.stop="handlePrintClick">
          인쇄하기
        </v-btn>
        <v-btn outlined @click="handleCloseClick">
          취소하기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { printDiv } from '@/utils/tools';
export default {
  computed: {
    ...mapGetters({
      dialog: 'dialog/bankTransferBillDialog',
      businessInfo: 'config/businessInfo'
    })
  },
  methods: {
    ...mapActions({
      closeBankTransferBillDialog: 'dialog/closeBankTransferBillDialog'
    }),
    handleCloseClick() {
      this.closeBankTransferBillDialog();
    },
    handlePrintClick() {
      printDiv('bankTransferBill', '노티아이 청구서 - 직접입금');
    }
  }
};
</script>

<style scoped>
.filled {
  background-color: #ddd;
}
</style>
