<template>
  <div>
    <v-row class="mt-2 mb-1">
      <v-col cols="12">
        <div class="filters mt-5 mb-8">
          <a-descriptions bordered>
            <a-descriptions-item label="날짜">
              <FilterDatePicker
                :style="{ display: 'inline-block' }"
                :prefix="7"
                @setDateRanges="
                  v => {
                    payload.dates = v;
                  }
                "
              />
            </a-descriptions-item>
            <a-descriptions-item label="광고 유형">
              <a-select
                mode="multiple"
                :style="{ minWidth: '150px' }"
                v-model="payload.campaignType"
              >
                <a-select-option
                  v-for="(item, index) in CampaignTypeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-descriptions-item>

            <a-descriptions-item label="사용 유형">
              <a-select
                mode="multiple"
                :style="{ minWidth: '150px' }"
                v-model="payload.codes"
              >
                <a-select-option
                  v-for="(item, index) in PointCodeFlags"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-descriptions-item>

            <a-descriptions-item label="구분">
              <a-select
                mode="multiple"
                :style="{ minWidth: '150px' }"
                v-model="payload.statusFlag"
              >
                <a-select-option
                  v-for="(item, index) in PointStatusFlags"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-descriptions-item>

            <a-descriptions-item label="캠페인명">
              <a-input v-model="payload.campaignName" />
            </a-descriptions-item>

            <a-descriptions-item label="금액">
              <a-input
                v-model="payload.amount[0]"
                class="text-right"
                :style="{ maxWidth: '150px', display: 'inline-block' }"
                suffix="원"
              />

              <span class="ml-2 mr-2"> ~ </span>

              <a-input
                v-model="payload.amount[1]"
                class="text-right"
                :style="{ maxWidth: '150px' }"
                suffix="원"
              />
            </a-descriptions-item>
          </a-descriptions>

          <div class="text-center mt-3">
            <v-btn
              color="#00d1b2"
              class="white--text"
              depressed
              :loading="loading"
              :disabled="loading"
              @click="getPoints"
            >
              검색
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="loading" type="table" />

    <a-table
      v-else
      bordered
      :columns="columns"
      :data-source="points"
      :pagination="false"
      :locale="{
        emptyText: `내역이 존재하지 않습니다.`,
      }"
    >
      <template slot="statusFlag" slot-scope="value, item">
        <a-tag :color="item.statusFlag.color">
          {{ item.statusFlag.text }}
        </a-tag>
      </template>

      <template slot="actions" slot-scope="value, record">
        <v-btn
          type="primary"
          small
          icon
          @click="openDialog(record)"
          v-if="record.code === '광고 요금'"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </a-table>

    <v-row class="mt-5">
      <v-col>
        <v-pagination
          v-model="payload.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
        />
      </v-col>
    </v-row>

    <point-usage-detail-dialog />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PointUsageDetailDialog from './dialog/PointUsageDetailDialog';
import { PointStatusFlags, PointCodeFlags } from '@/config/charge';
import { CampaignTypeList } from '@/config/campaign';

export default {
  components: {
    PointUsageDetailDialog,
    FilterDatePicker: () => import('@/components/FilterDatePicker.vue'),
  },
  computed: {
    ...mapGetters({
      columns: 'memberPaymentPointUsage/columns',
      points: 'memberPaymentPointUsage/buckets',
      loading: 'memberPaymentPointUsage/loading',
      payload: 'memberPaymentPointUsage/payload',
      pagination: 'memberPaymentPointUsage/pagination',
    }),
  },
  data() {
    return {
      PointStatusFlags: PointStatusFlags,
      PointCodeFlags: PointCodeFlags,
      CampaignTypeList: CampaignTypeList,
    };
  },
  mounted() {
    this.getPoints();
  },
  methods: {
    ...mapActions({
      getPoints: 'memberPaymentPointUsage/index',
      openDialog: 'memberPaymentPointDetail/openDialog',
    }),
  },
};
</script>

<style></style>
