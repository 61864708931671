import { render, staticRenderFns } from "./PointUsageTab.vue?vue&type=template&id=420d3c5f&"
import script from "./PointUsageTab.vue?vue&type=script&lang=js&"
export * from "./PointUsageTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports