<template>
  <v-dialog
    :value="dialog"
    width="800"
    scrollable
    persistent
    eager
    style="max-width: 800px"
  >
    <v-card :loading="loading">
      <v-toolbar flat dark>
        <v-toolbar-title> 청구서 - 카드결제 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-if="!loading" class="mt-5" id="creditCardBill">
        <h2>공급자</h2>
        <v-simple-table class="elevation-1" style="white-space: nowrap">
          <tbody>
            <tr>
              <td class="filled">사업자 등록번호</td>
              <td colspan="3">{{ businessInfo.businessNumber }}</td>
            </tr>
            <tr>
              <td class="filled">업체명</td>
              <td>{{ businessInfo.name }}</td>
              <td class="filled">대표자 명</td>
              <td>{{ businessInfo.presidentName }}</td>
            </tr>
            <tr>
              <td class="filled">주소</td>
              <td colspan="3">{{ businessInfo.address }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-divider class="my-5"></v-divider>
        <h2>거래정보</h2>
        <v-simple-table class="elevation-1" style="white-space: nowrap">
          <tbody>
            <tr>
              <td class="filled">구매자 명(회원ID)</td>
              <td>{{ pointOrder.userName }} ({{ pointOrder.userId }})</td>
              <td class="filled">PG</td>
              <td>신용카드 - 티디아이파이낸셜</td>
            </tr>
            <tr>
              <td class="filled">결제번호</td>
              <td>{{ pointOrder.orderNo }}</td>
              <td class="filled">거래일시(승인번호)</td>
              <td>
                <div>{{ pointOrder.orderAt }}</div>
                <div>({{ pointOrder.paymentToken }})</div>
              </td>
            </tr>
            <tr>
              <td class="filled">결제수단</td>
              <td>신용카드</td>
              <td class="filled">금액</td>
              <td>
                {{ pointOrder.advertisingAmount | KRW }}
              </td>
            </tr>
            <tr>
              <td class="filled">거래구분</td>
              <td>XX페이먼츠</td>
              <td class="filled">부가세</td>
              <td>
                {{ pointOrder.taxAmount | KRW }}
              </td>
            </tr>
            <tr>
              <td class="filled">상품명</td>
              <td>노티아이 포인트 충전</td>
              <td class="filled">합계</td>
              <td>
                {{ pointOrder.amount | KRW }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <ul class="mt-3">
          <li>
            해당 청구서는 거래내역 확인을 위한 용도이며, 회계처리 증빙으로
            사용이 불가능합니다.
          </li>
          <li>
            부가가치세 신고는 별도로 발행되는 세금계산서로 처리하여 주시기
            바랍니다.
          </li>
          <li>세금계산서 관련 문의는 1:1문의를 이용해주시기 바랍니다.</li>
          <li>
            신용카드전표가 필요한 경우, 아래 사이트에서 출력가능하나 부가가치세
            신고 용도로 사용 할 수 없습니다.
          </li>
        </ul>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="!loading" class="justify-center">
        <v-btn color="#ddd" @click.stop="handlePrintClick">
          <v-icon>mdi-printer</v-icon>
          인쇄하기
        </v-btn>
        <v-btn outlined @click="handleCloseClick"> 취소하기 </v-btn>
      </v-card-actions>

      <v-skeleton-loader v-if="loading" type="card"> </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { printDiv } from '@/utils/tools';
import { getPointOrderApi } from '@/api/advertiser/UserAPI';
import { PointOrder } from '@/model/advertiser/PointOrder';
export default {
  data() {
    return {
      loading: true,
      pointOrder: new PointOrder()
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/creditCardBillDialog',
      businessInfo: 'config/businessInfo',
      orderNo: 'point/orderNo'
    })
  },
  mounted() {
    this.fetchPointOrder();
  },
  methods: {
    ...mapActions({
      closeCreditCardBillDialog: 'dialog/closeCreditCardBillDialog',
      initOrderNo: 'point/initOrderNo'
    }),
    async fetchPointOrder() {
      this.loading = true;
      const [error, data] = await getPointOrderApi(this.orderNo);
      if (error) {
        console.log(error);
      } else {
        const { point_order } = data;

        const pointOrder = new PointOrder();

        pointOrder.userId = point_order.user_id;
        pointOrder.userName = point_order.user_name;
        pointOrder.orderAt = point_order.order_at;
        pointOrder.orderNo = point_order.order_no;
        pointOrder.amount = Number(point_order.amount);
        pointOrder.advertisingAmount = Number(point_order.advertising_amount);
        pointOrder.paymentToken = point_order.payment_token;
        pointOrder.taxAmount = Number(point_order.tax_amount);

        this.pointOrder = pointOrder;
      }
      this.loading = false;
    },
    handleCloseClick() {
      this.initOrderNo();
      this.closeCreditCardBillDialog();
    },
    handlePrintClick() {
      printDiv('creditCardBill', '노티아이 청구서 - 카드결제');
    }
  }
};
</script>

<style scoped>
.filled {
  background-color: #ddd;
}
</style>
