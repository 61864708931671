<template>
  <div>
    <v-row class="mt-2 mb-1">
      <v-col cols="12">
        <div class="filters mt-5 mb-8">
          <a-descriptions bordered>
            <a-descriptions-item label="날짜">
              <FilterDatePicker
                :style="{ display: 'inline-block' }"
                :prefix="7"
                @setDateRanges="
                  v => {
                    payload.dates = v;
                  }
                "
              />
            </a-descriptions-item>
            <a-descriptions-item label="유상/무상">
              <a-select
                mode="multiple"
                :style="{ minWidth: '150px' }"
                v-model="payload.typeFlag"
              >
                <a-select-option value="C"> 유상 </a-select-option>
                <a-select-option value="F"> 무상 </a-select-option>
              </a-select>
            </a-descriptions-item>

            <a-descriptions-item label="주문번호">
              <a-input v-model="payload.orderNo" />
            </a-descriptions-item>
          </a-descriptions>

          <div class="text-center mt-3">
            <v-btn
              color="#00d1b2"
              class="white--text"
              depressed
              :loading="loading"
              :disabled="loading"
              @click="getPoints"
            >
              검색
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
    <a-table
      v-else
      bordered
      :columns="columns"
      :data-source="points"
      :pagination="false"
      :locale="{
        emptyText: `내역이 존재하지 않습니다.`,
      }"
    >
    </a-table>
    <!--
    <div class="text-center ma-3">
      <v-btn
        v-if="pagination.hasMorePage"
        class="mx-auto"
        @click="handleHasMoreClick()"
        text
        :loading="apiLoading"
        >▼ 더보기 ▼</v-btn
      >
    </div>
    -->

    <v-row class="mt-5">
      <v-col>
        <v-pagination
          v-model="payload.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PointTypeFlags } from '@/config/charge';

export default {
  props: {
    type: {
      type: String,
    },
  },
  components: {
    FilterDatePicker: () => import('@/components/FilterDatePicker.vue'),
  },
  computed: {
    ...mapGetters({
      columns: 'memberPaymentPointCharge/columns',
      points: 'memberPaymentPointCharge/buckets',
      loading: 'memberPaymentPointCharge/loading',
      payload: 'memberPaymentPointCharge/payload',
      pagination: 'memberPaymentPointCharge/pagination',
    }),
  },
  data() {
    return {
      PointTypeFlags: PointTypeFlags,
    };
  },
  watch: {
    payload: {
      immediate: true,
      deep: true,
      handler() {
        this.getPoints();
      },
    },
  },
  mounted() {
    this.getPoints();
  },
  methods: {
    ...mapActions({
      getPoints: 'memberPaymentPointCharge/index',
    }),
  },
};
</script>

<style></style>
