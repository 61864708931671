export function PointOrder(
  userId,
  userName,
  orderAt,
  orderNo,
  amount,
  advertisingAmount,
  taxAmount,
  paymentToken
) {
  this.userId = userId;
  this.userName = userName;
  this.orderAt = orderAt;
  this.orderNo = orderNo;
  this.amount = Number(amount);
  this.advertisingAmount = Number(advertisingAmount);
  this.taxAmount = Number(taxAmount);
  this.paymentToken = paymentToken;
}
