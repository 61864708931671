<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>사용내역 상세정보</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <div class="mt-5">
          <a-descriptions bordered title="캠페인 정보">
            <a-descriptions-item label="날짜">
              {{ record.transactionAt }}
            </a-descriptions-item>
            <a-descriptions-item label="광고유형" :span="2">
              {{ record.campaignType }}
            </a-descriptions-item>

            <a-descriptions-item
              :label="
                item.type_flag === 'C' ? '유상 소진금액' : '무상 소진금액'
              "
              v-for="(item, index) in point"
              :key="index"
            >
              {{ item.sum_amount.toLocaleString() }} 원
            </a-descriptions-item>

            <a-descriptions-item label="합계 소진금액">
              <div class="text-right">{{ record.sumAmount }} 원</div>
            </a-descriptions-item>

            <a-descriptions-item label="캠페인명">
              {{ record.campaignName }}
            </a-descriptions-item>
          </a-descriptions>
        </div>

        <a-spin :spinning="loading" class="mb-5">
          <a-descriptions class="mt-8">
            <div slot="title">
              <v-row>
                <v-col cols="6"> 상세 사용내역 </v-col>

                <v-col cols="6" class="text-right">
                  <v-btn
                    small
                    dark
                    depressed
                    :loading="loading"
                    :readonly="loading"
                    @click="getPoints()"
                  >
                    새로고침
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </a-descriptions>

          <a-table
            bordered
            :columns="columns"
            :data-source="points"
            :pagination="false"
            :locale="{
              emptyText: `내역이 존재하지 않습니다.`,
            }"
          >
            <template slot="statusFlag" slot-scope="value, item">
              <a-tag :color="item.statusFlag.color">
                {{ item.statusFlag.text }}
              </a-tag>
            </template>
          </a-table>

          <!--
          <v-row class="mt-5">
            <v-col>
              <v-pagination
                v-model="payload.page"
                :length="pagination.totalPage"
                :total-visible="10"
                color="secondary"
              />
            </v-col>
          </v-row>
          -->
        </a-spin>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  computed: {
    ...mapGetters({
      dialog: 'memberPaymentPointDetail/dialog',
      record: 'memberPaymentPointDetail/record',
      columns: 'memberPaymentPointDetail/columns',
      points: 'memberPaymentPointDetail/buckets',
      loading: 'memberPaymentPointDetail/loading',
      pagination: 'memberPaymentPointDetail/pagination',
      payload: 'memberPaymentPointDetail/payload',
      point: 'memberPaymentPointDetail/point',
    }),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      closeDialog: 'memberPaymentPointDetail/closeDialog',
      getPoints: 'memberPaymentPointDetail/index',
    }),
    close() {
      this.closeDialog();
    },
  },
};
</script>
